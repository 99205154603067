@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

body {
  overflow: visible;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  height: -webkit-fill-available;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-right: env(safe-area-inset-right);
  padding-left: env(safe-area-inset-left);
  max-width: 500px;
  font-family: "IBM Plex Mono", monospace;
  background-color: #121212;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Header */
header {
  padding: 16px 24px;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #121212;
  border-bottom: 1px solid #252525;
}
.App {
  height: 90vh;
}
button.icon-button {
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex; /* Use flexbox */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  padding: 0; /* Remove any default padding */
  box-sizing: border-box; /* Ensure consistent sizing */
}

button.icon-button:hover {
  background: #151515;
  border-radius: 4px;
}

/* Font styling */

.logo {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 600;
  font-size: 16px;
  color: rgba(71, 255, 61, 0.866667);
}

.heading {
  font-family: "IBM Plex Mono", monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: rgba(238, 238, 238, 0.866667);
  width: 60%; /* Constrain the width to 50% of the parent */
  max-width: 60%; /* Ensure the text doesn't overflow */
  white-space: normal; /* Allow text to wrap if necessary */
  overflow: hidden; /* Ensure no text overflows the container */
  display: block; /* Ensure proper block-level behavior */
  word-wrap: break-word; /* Break words if necessary to fit */
  position: relative;
  height: 48px;
}

.cursor {
  display: inline-block;
  animation: blink 0.5s step-end infinite; /* Blinking effect */
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.paragraph-secondary {
  font-family: "IBM Plex Mono", monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: justify;
  color: #7d7d7d;
  margin: 0px;
}

.paragraph-primary {
  font-family: "IBM Plex Mono", monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(238, 238, 238, 0.866667);
}

.button {
  font-family: "IBM Plex Mono", monospace;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #121212;
}

.caption2 {
  font-size: 12px;
}

.caption {
  font-family: "IBM Plex Mono", monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  /* Grey Dark */
  color: #7d7d7d;
}

/* Border */
/* .border {
  height: 1px;
  width: 100%;
  background-color: #252525;
} */

/* LogoHeader */
.logoHeader {
  display: flex;
  flex-direction: row;
  margin-right: 2px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  align-items: center;
}

a {
  text-decoration: none;
}

a:visited,
a:focus,
a:hover,
a:active {
  text-decoration: none;
}

/* Main */

main h1 {
  width: 50%;
}
main {
  margin: 40px 0px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

/* Button */
.btnPrimary {
  padding: 8px;
  height: 40px;
  background: rgba(71, 255, 61, 0.866667);
  border-radius: 4px;
  border-style: none;
}

.btnPrimary:hover {
  background: rgba(71, 255, 61, 0.866667);
  box-shadow: 0px 0px 12px rgba(71, 255, 61, 0.2);
}

/* Footer */
main.footer {
  display: rgba(147, 149, 147, 0.867);
  flex-direction: column;
  gap: 32px;
  padding: 24px;
  background-color: #151515;
  border-radius: 8px;
}

footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.agentAi img {
  width: 24px;
  height: 24px;
}

.agentAi {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

/* Responsive */
@media (max-width: 390px) {
  .nlux-composer-container {
    position: fixed !important; /* Stick to the viewport */
    bottom: 0 !important; /* Stay at the bottom */
    left: 0 !important; /* Align to the left edge */
    width: 100% !important; /* Fit the full width of the viewport */
    z-index: 1000 !important; /* Ensure it's on top of other elements */
    max-width: 100% !important; /* Prevent overflow beyond the viewport */
    background-color: #151515 !important;
    color: rgba(238, 238, 238, 0.87) !important;
  }
  main {
    margin: 24px 16px;
  }
  .heading {
    height: 62px;
  }
  main,
  .footer {
    padding: 16px;
  }
  .heading {
    height: 64px;
  }
}

/* Chat message */
.nlux-launchPad-container > .nlux-conversationStarters-container {
  flex: 0 !important;
}

/* User message */
.nlux-comp-chatItem.nlux-comp-chatItem--bubblesLayout.nlux-comp-chatItem--sent
  > .nlux-comp-message {
  background-color: #121212 !important;
  color: rgba(238, 238, 238, 0.87) !important;
}

/* AI Message */
.nlux-comp-chatItem.nlux-comp-chatItem--bubblesLayout.nlux-comp-chatItem--received
  > .nlux-comp-message {
  background-color: rgba(71, 255, 61, 0.87) !important;
  color: #151515 !important;
}

/* Chat messages */
.nlux-comp-chatItem {
  margin-bottom: 1em !important;
}

/* Font family */
.nlux-AiChat-root.nlux-theme-nova {
  --nlux-nova--FontFamily: "IBM Plex Mono" !important;
}

.nlux-AiChat-root {
  font-size: 14px !important;
}

.nlux-AiChat-root.nlux-theme-nova {
  --nlux-ChatRoom--BorderRadius: 0px 0px 12px 12px !important;
}

.nlux-AiChat-root.nlux-theme-nova {
  --nlux-nova--FontFamily: "IBM Plex Mono" !important;
}

/* Welcome message */
.nlux-comp-welcomeMessage > .nlux-comp-welcomeMessage-personaName {
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: "IBM Plex Mono" !important;
}

.nlux-comp-welcomeMessage > .nlux-comp-welcomeMessage-text {
  font-size: 14px !important;
  font-weight: 400 !important;
  text-align: center !important;
  padding: 20px !important;
}

/* AI Avatar image radius */
.nlux-comp-avatar {
  border-radius: 0px !important;
  width: 32px !important;
}

/* Fixing the chat component */
.nlux-composer-container {
  position: fixed !important; /* Fixes the composer relative to the viewport */
  bottom: 0 !important; /* Aligns it to the bottom */
  left: 50% !important; /* Positions the left edge at the center of the viewport */
  transform: translateX(
    -50%
  ) !important; /* Shifts it left by 50% of its own width to center */
  width: 100% !important; /* Allows it to be responsive */
  max-width: 500px !important; /* Constrains it to 500px on larger screens */
  z-index: 1000 !important; /* Ensures it stays above other elements */
  background-color: #151515 !important;
  color: rgba(238, 238, 238, 0.87) !important;
  margin: 0 auto;
}

.nlux-chatRoom-container {
  position: relative !important;
  height: 100% !important; /* Ensure it spans the entire height of the parent */
  box-sizing: border-box !important;
}

.nlux-AiChat-root {
  position: relative; /* Establishes a containing block for absolutely positioned children if needed */
  margin: 0 auto; /* Centers the container horizontally */
  width: 100%; /* Full width on smaller screens */
  max-width: 500px; /* Constrain width to 500px on larger screens */
  min-height: 100vh !important; /* Ensure it takes up the full viewport height */
  background-color: #f9f9f9; /* Optional: background for the chat container */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  padding-bottom: 60px; /* Ensure content isn't hidden behind the fixed composer */
}

/* Link styling */
a {
  color: #7d7d7d;
}

a:hover {
  color: #eee;
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.nlux-AiChat-root > .nlux-chatRoom-container {
  border-radius: 0px !important;
}
